<template>
  <div class="card" style="min-width: 100%; max-width: fit-content">
    <div class="card-body">
      <h1>Criar encomenda</h1>
      <hr />

      <section>
        <div></div>
      </section>

      <section>
        <h3>Selecione o ficheiro e folha</h3>
        <input class="" type="file" @change="onChange" />
        <xlsx-read :file="file">
          <xlsx-sheets>
            <template #default="{ sheets }">
              <select
                class="form-select form-select-sm mt-1"
                style="width: 150px"
                v-model="selectedSheet"
              >
                <option v-for="sheet in sheets" :key="sheet" :value="sheet">
                  {{ sheet }}
                </option>
              </select>
            </template>
          </xlsx-sheets>
          <xlsx-json :sheet="selectedSheet" @parsed="parsed"> </xlsx-json>
        </xlsx-read>
      </section>

      <!--button to download example csv-->
      <section>
        <xlsx-workbook 
        >
        <xlsx-sheet
          :collection="sheet.data"
          v-for="sheet in sheetExample"
          :key="sheet.name"
          :sheet-name="sheet.name"
        />
        <xlsx-download
          :filename="`Ficheiro_exemplo.xlsx`"
        >
          <button class="btn btn-sm mt-1 btn-download-example"
          style="width: 150px">Exemplo</button>
        </xlsx-download>
      </xlsx-workbook>        
      </section>
    </div>

    <!--input for field with detail of order-->
    <div class="card-body pt-1">
      <h5>Observações da encomenda</h5>
      <input
        class="form-control form-control-sm"
        type="text"
        maxlength="66"
        v-model="orderDetail"
        placeholder="Observações"
      />
    </div>

    <div class="card-body pt-1">
      <div class="border">
        <div class="mt-4 ms-2 col-12 row">
          <label
            class="fs-6 fw-bold col-auto"
            style="align-self: center"
            for="product"
            >Escolher produto:&nbsp;&nbsp;</label
          >
          <!-- while products not loaded show loading -->
          <label
            v-if="!productsList || productsList.length == 0"
            class="col-auto mt-sm-3 mb-sm-3"
          >
            A carregar <i class="fas fa-spinner fa-spin"></i>
          </label>

          <input
            v-if="productsList && productsList.length > 0"
            @change="onChangeProduct($event)"
            class="form-control form-control-sm"
            style="width: 150px"
            list="products"
            id="product"
            ref="productRef"
            name="product"
          />

          <datalist id="products">
            <option v-for="(prod, index) in productsList" :key="index">
              {{ prod.refAndDesign }}
            </option>
          </datalist>
          <label
            v-if="false"
            class="fs-6 fw-bold col-auto ms-xxl-2"
            style="align-self: center"
            for="product"
            >Familia:&nbsp;&nbsp;</label
          >
          <!--Select from families not used in this project-->
          <select
            v-if="false"
            class="form-select form-select-sm mt-1"
            style="width: 150px"
            v-model="selectedFamily"
            @change="onChangeFamily($event)"
          >
            <option value="">Todos</option>
            <option v-for="fam in families" :value="fam.ref" :key="fam.ref">
              {{ fam.nome }}
            </option>
          </select>
        </div>

        <hr style="width: 98%; margin: auto; margin-top: 10px" />

        <form class="form">
          <div class="card-body fs-8">
            <div class="form-group row">
              <div class="col-12 col-xl-3 d-flex">
                <label for="artigo" class="col col-form-label fs-6 fw-bold"
                  >Referência:</label
                >
                <div class="col-7">
                  <input
                    readonly
                    type="text"
                    class="form-control form-control-solid"
                    id="inputrefe"
                    v-model.trim="productField.refe"
                  />
                </div>
              </div>
              <div class="col-12 col-xl-3 d-flex">
                <label for="artigo" class="col col-form-label fs-6 fw-bold"
                  >Designação:</label
                >
                <div class="col-7">
                  <input
                    readonly
                    type="text"
                    class="form-control form-control-solid"
                    id="inputDesignation"
                    v-model.trim="productField.design"
                  />
                </div>
              </div>
              <div class="col-12 col-xl-3 d-flex">
                <label for="artigo" class="col col-form-label fs-6 fw-bold"
                  >Unid. Embalagem (info):</label
                >
                <div class="col-7">
                  <input
                    readonly
                    type="text"
                    class="form-control form-control-solid"
                    id="inputPrice"
                    v-model.trim="productField.u_qtdemb"
                  />
                </div>
              </div>

              <div class="col-12 col-xl-3 d-flex">
                <label for="artigo" class="col col-form-label fs-6 fw-bold"
                  >Quantidade (unid):</label
                >
                <div class="col-7">
                  <input
                    type="number"
                    class="form-control"
                    id="inputQtt"
                    ref="qttRef"
                    @keyup.enter="goToProductSelection()"
                    v-model="productField.qtt"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end me-2 mb-2">
          <button
            v-if="editingIndex !== null"
            class="btn btn-color"
            @click="cleanProductField"
          >
            Nova Linha
          </button>
          <button
            v-if="editingIndex === null"
            class="btn btn-add"
            @click="addProduct"
            :disabled="
              (!productField.design && !productField.refe) || !productField.qtt
            "
          >
            Adicionar
          </button>
        </div>
      </div>
    </div>
    <div>
      <div v-if="list && list.length" class="col-12 col-md-4 d-flex mx-10">
        <label for="inputObraNum" class="col-auto col-form-label fs-6 fw-bold"
          >Número encomenda interno:</label
        >
        <div class="col-3 ms-3">
          <input
            type="text"
            class="form-control"
            id="inputObraNum"
            v-model="formToSend.obranome"
          />
        </div>
      </div>
      <FieldErrorMessage
        class="mt-1 ms-10"
        :errors="errors"
        fieldName="obrano"
      ></FieldErrorMessage>
    </div>

    <div>
      <new-order-table
        style="min-width: 100%; max-width: 100px"
        v-if="list && list.length"
        :list="list"
        @editLine="editLine"
        @removeLine="removeLine"
      />
    </div>

    <!--Button to submit-->
    <div v-if="list && list.length" class="d-flex justify-content-end m-5">
      <button class="btn btn-color" @click="submit" :disabled="!list.length">
        Enviar encomenda
      </button>
    </div>
    <!--End submit buyton-->
  </div>
</template>

<script>
//npm install -save vue3-xlsx
import {
  XlsxRead,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
} from "/node_modules/vue3-xlsx/dist/vue3-xlsx.cjs.prod.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import NewOrderTable from "@/components/Orders/NewOrderTable.vue";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import FieldErrorMessage from "@/components/global/FieldErrorMessage.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "ImportCsvAndConvertInOrder",
  components: {
    XlsxRead,
    XlsxSheets,
    XlsxJson,
    FieldErrorMessage,
    NewOrderTable,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  data() {
    return {
      file: null,
      selectedSheet: null,
      sheetName: null,
      sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
      sheetExample : {},
      collection: [{ a: 1, b: 2 }],
      list: [],
      productField: {},
      editingIndex: null,
      productsList: [],
      formToSend: {},
      errors: [],
      families: [],
      selectedFamily: "",
      orderDetail: "",
    };
  },
  computed: {
    storedProducts() {
      return store.getters.getProducts;
    },
  },
  watch: {
    storedProducts() {
      this.productsList = this.concatRefAndDesign(store.getters.getProducts);
    },
  },
  methods: {
    /*
    * ----------------DATA MANIPULATION----------------
    */
    onChangeProduct(e) {
      //some products have no refe so clean before search

      //separate refe and designation by -
      let refe = e.target.value.split(" - ")[0];

      this.productField = {};
      this.productField.design = e.target.value;

      if (!refe.trim().length) return;

      let product = this.productsList.find(
          (prod) => prod.ref.trim() === refe.trim()
      );

      if (product) {
        this.productField.refe = product.ref;
        this.productField.design = product.design;
        this.productField.qtt = product.qtt;
        this.productField.u_qtdemb = product.u_qtdemb;
      } else {
        e.target.value = "";
        this.cleanProductField();
        return;
      }
      //if update on line keep existing qtt and save productField
      if (this.editingIndex !== null) {
        this.productField.qtt = this.list[this.editingIndex].qtt;
        this.productField.refe = product.ref;
        this.productField.design = product.design;
        this.productField.u_qtdemb = product.u_qtdemb;
        this.list[this.editingIndex] = this.productField;
      }

      e.target.value = "";

      //set cursor to be on qtt
      this.$nextTick(() => {
        this.$refs.qttRef.focus();
      });
    },

    removeLine(index) {
      this.list.splice(index, 1);
    },
    cleanProductField() {
      this.productField = {};
      this.editingIndex = null;
    },
    editLine(index) {
      this.productField = this.list[index];
      this.editingIndex = index;
    },
    addProduct() {
      if (this.editingIndex === null) {
        this.list.push(this.productField);
        this.productField = {};
      } else {
        this.list[this.editingIndex] = this.productField;
        this.productField = {};
        this.editingIndex = null;
      }

      //set cursor to be on product id
      this.$nextTick(() => {
        this.$refs.productRef.focus();
      });
    },

    onChangeFamily() {
      store.dispatch("loadProducts", "&familia=" + this.selectedFamily);
      this.productsList = [];
    },

    parsed(val) {
      let subset = [];
      val.forEach((item) => {
        subset.push({
          refe: item.Ref,
          design: "",
          qtt: item.Qtt,
        });
      });
      this.list = subset;

      this.file = null;
      //this.selectedSheet = null;
      this.sheetName = null;
      this.sheets = [{ name: "SheetOne", data: [{ c: 2 }] }];
      this.collection = [{ a: 1, b: 2 }];
    },
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },
    addSheet() {
      this.sheets.push({ name: this.sheetName, data: [...this.collection] });
      this.sheetName = null;
    },

    createExampleXLSX() {
      this.sheetExample = [];

      //create one sheet
      this.sheetExample.push({
        name: "Encomendas",
        data: [
          {
            Ref: "A123",
            Qtt: "1",
          },
          {
            Ref: "B456",
            Qtt: "2",
          },
          {
            Ref: "C789",
            Qtt: "3",
          },
        ],
      });
    },

    //Not in use
    generateAndDownloadExampleCSV() {

      const rows = [
        ["Ref", "Qtt"],
        ["A123", "1"],
        ["B456", "2"],
        ["C789", "3"],
      ];

      const csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);

      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "example.csv");
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
    },


    /*
    * -----------------API STORE----------------------
    */

    submit() {
      //put client num on formToSend
      this.formToSend.no = store.getters.currentAuthUser.numcl;
      //put list as Lines in form
      this.formToSend.lines = this.list;
      this.formToSend.obranome =
          this.formToSend.obranome !== undefined
              ? this.formToSend.obranome.trim()
              : "";

      if (!this.verifyForm()) return;

      this.formToSend.obs = this.orderDetail.trim();

      ApiService.setHeader();
      ApiService.post("OrderToPhc", this.formToSend)
          .then((response) => {
            Swal.fire({
              title: "Encomenda enviada com sucesso",
              icon: "success",
              confirmButtonText: "Ok!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
            //clear everything
            this.formToSend = {};
            this.list = [];
            this.productField = {};
            this.editingIndex = null;
            this.file = null;
            this.selectedSheet = null;
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              title: "Erro ao enviar encomenda",
              icon: "error",
              confirmButtonText: "Ok!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
          });
    },


    /*
    * ------------------API FETCHING------------------
    */

    loadFamilies() {
      ApiService.get("products/families", "?PageSize=99999").then((res) => {
        this.families = this.orderFamilies(res.data.data);
      });
    },

    /*
    * ------------------EMITS------------------
    */

    /*
    * ------------------VALIDATIONS------------------
     */
    verifyForm() {
      this.errors = [];
      let errorsQtt = 0;
      //obra num must be filled
      if (
          !this.formToSend.obranome ||
          !this.formToSend.obranome.trim().length
      ) {
        this.errors["obrano"] = "Numero de encomenda deve ser preenchido";
        errorsQtt++;
      }

      //remove all lines that have no (design and refe) or qtt
      this.list = this.list.filter((line) => {
        if ((!line.design && !line.design.trim().length) && (!line.refe && !line.refe.trim().length)) {
          return false;
        }
        if (!line.qtt) {
          return false;
        }
        return true;
      });

      //this.list = this.list.filter((line) => line.design !== undefined && line.design.trim().length);

      if (!this.list.length) {
        Swal.fire({
          title: "Erro",
          text: "Não existem linhas para enviar",
          type: "error",
          confirmButtonText: "Ok",
        });
        errorsQtt++;
      }

      //convert every form.ref in string
      this.list.forEach((item) => {
        if (item.refe) {
          item.refe = item.refe.toString();
        }
      });

      return errorsQtt === 0;
    },

    /*
    * -------------------UTILS--------------------
    */
    concatRefAndDesign(products) {
      products.forEach((product) => {
        product.refAndDesign =
            product.ref.trim() + " - " + product.design.trim();
      });

      return products;
    },
    orderFamilies(families) {
      //remove empty ref
      families = families.filter((family) => family.ref.trim().length);

      //order by ref
      families.sort((a, b) => {
        if (a.nome < b.nome) return -1;
        if (a.nome > b.nome) return 1;
        return 0;
      });

      //remove duplicated ref
      families = families.filter((family, index) => {
        if (index === 0) return true;
        if (family.ref === families[index - 1].ref) return false;
        return true;
      });

      return families;
    },

    goToProductSelection() {
      //set cursor to be on product id
      this.addProduct();
      this.$nextTick(() => {
        this.$refs.productRef.focus();
      });
    },




    loadProducts() {
      store.dispatch("loadProducts");
    },


    /*
    * -------------------STYLES-------------------
    */


    
  },
  mounted() {
    setCurrentPageBreadcrumbs("Nova Encomenda");
    this.loadProducts();
    this.loadFamilies();
    this.createExampleXLSX();
  },
};
</script>
<style scoped>
.btn-color {
  background-color: #d9eecc;
  color: #3d9a01 !important;
}

.btn-color:hover {
  background-color: #e8f5e0;
}

.btn-add:hover {
  background-color: #adadad;
}

.btn-add {
  background-color: #bdb4b6ff;
  color: black;
}

.btn-download-example {
  margin-right: 20px;
  background-color: #BDB4B6ff;
  color: black;
  width: 10%;
  font-size: 15px;
}

.btn-download-example:hover {
  background-color: #adadad;  
}
</style>