<template>
  <!--begin::Body-->
  <div class="card-body mb-5 py-3">
    <!--begin::Table container-->
    <div class="table-responsive fixTableHead">
      <!--begin::Table-->
      <table
        class="table able-condensed small table-row-gray-500 gy-5 gs-5 mb-0"
      >
        <!--begin::Table head-->
        <thead id="table-head">
          <tr class="fw-bolder text-muted">
            <th class="text-dark fw-bolder">Referência</th>
            <th class="text-dark fw-bolder">Descrição</th>
            <th class="text-dark fw-bolder">Quantidade</th>
            <th class="text-end"></th>
          </tr>
        </thead>
        <!--end::Table head-->

        <!--begin::Table body-->
        <tbody>
          <template v-for="(item, index) in list" :key="index">
            <tr class="clickable-row">
              <td @click="editLine(index)" class="fs-6 align-middle">{{ item.refe }}</td>
              <td @click="editLine(index)" class="fs-6 align-middle">{{ item.design }}</td>
              <td @click="editLine(index)" class="fs-6 align-middle">{{ item.qtt }}</td>
              <td class="text-end">
                <button
                  type="button"
                  class="btn btn-icon btn-danger btn-sm"
                  style="color: white !important; background-color: #e30b17;"
                  @click="removeLine(index)"
                >
                  <div class="symbol-label">
                    <span class="svg-icon svg-icon-2x svg-icon-danger mb-2">
                      <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
                    </span>
                  </div>
                </button>
              </td>
            </tr>
          </template>
        </tbody>
        <!--end::Table body-->
      </table>
      <!--end::Table-->
    </div>
    <!--end::Table container-->
  </div>
  <!--begin::Body-->
</template>

<script>
export default {
  name: "NewOrderTable",
  props: {
    list: Array,
  },
  emits: ["editLine", "removeLine"],
  methods: {
    editLine(index) {
      this.$emit("editLine", index);
    },
    removeLine(index) {
      this.$emit("removeLine", index);
    },
  },
};
</script>


<style scoped>
.fixTableHead {
  overflow-y: auto;
}
.fixTableHead thead th {
  position: sticky;
  top: 10;
}
table {
  border-collapse: collapse;
  width: 100%;
}

th {
  color: white !important;
  text-align: -webkit-center;
}

td {
  text-align: -webkit-center;
  border-bottom: 2px solid white;
}

tbody tr:nth-child(even) {
  background-color: #fbfbfb;
  
}

tbody tr:nth-child(odd) {
  background-color: #efefef;
}

#table-head {
  background-color: #791b1d;
}

#statusBadge {
  border-radius: 15px;

  text-align: center;
}

.btn-color{
  background-color: #BDB4B6ff;
  color: black;
}

.btn-color:hover{
    background-color: #adadad;
}

.btn-danger {
  background-color: #e30b17 !important;
  color: white;
}


.clickable-row {
  cursor: pointer;
}
</style>
